import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const EmployeeCard = ({ iconImage, subheader, header, body }) => {
    return (
      <Card style={{ borderRadius: '22px', height: '500px', width: '400px', marginBottom: '24px' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {iconImage && (
            <img
              src={iconImage}
              alt="Icon"
              style={{ width: '100px', height: 'auto', margin: '20px 0 0 20px' }}
            />
          )}
          <Typography variant="h5" component="div" mt={2} style={{ margin: '10px', fontWeight: 'bold', fontSize: '26px', textAlign: 'center', width: '80%' }}>
            {header}
          </Typography>
          <Typography variant="h6" color="text.secondary" mt={2} style={{ margin: '10px', fontSize: '20px', textAlign: 'center' }}>
            {subheader}
          </Typography>
          <Typography variant="h6" color="text.secondary" mt={2} style={{ margin: '10px', fontSize: '20px', textAlign: 'center' }}>
            {body}
          </Typography>
          
          {/* Added row with social media logos */}
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '80%', paddingTop: '20px' }}>
            <a href="https://www.example.com/x" target="_blank" rel="noopener noreferrer">
              <img
                src="/X.svg"
                alt="X Logo"
                style={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </a>
            <a href="https://www.linkedin.com/in/your-linkedin-profile" target="_blank" rel="noopener noreferrer">
              <img
                src="/LinkedIn.svg"
                alt="LinkedIn Logo"
                style={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </a>
            <a href="https://www.instagram.com/your-instagram-profile" target="_blank" rel="noopener noreferrer">
              <img
                src="Basketball.svg"
                alt="Logo"
                style={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </a>
          </div>
        </CardContent>
      </Card>
    );
  };  

export default EmployeeCard;