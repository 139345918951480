import React from "react";
import {
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = {

    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
    },
    image: {
      width: '30vw',
      paddingLeft: '15vw',
      paddingRight: '5vw',
      height: 'auto',
      marginRight: '16px', // Assuming theme.spacing(2) corresponds to 16px
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: '1000',
      fontSize: '80px', // Default font size for larger screens (lg)
      color: '#FEF8F1',
      textAlign: 'center',
        '@media (max-width: 1200px)': {
          fontSize: '60px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '50px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '40px', // Adjust for extra-small screens (xs)
        },
    },
    subheader: {
      marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
      marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
      fontSize: '24px', // Default font size for larger screens (lg)
      minWidth: '450px',
      fontWeight: 'bold',
      color: '#FDD06B',
      textAlign: 'center',
        '@media (max-width: 1200px)': {
          fontSize: '20px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '18px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '16px', // Adjust for extra-small screens (xs)
        },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px', // Assuming theme.spacing(1) corresponds to 8px
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px', // Assuming theme.spacing(1) corresponds to 8px
      color: '#FF4848', // Replace with your desired color
    },
  };

  function ElevateSection({ title, subtitle, items, buttonText, learnMoreText }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed
    return (
      <div style={{ backgroundColor: '#123E64'}}>
        <div style={{
          ...styles.root,
          backgroundColor: '#123E64',
          overflow: 'hidden',
          minHeight: '50vh',
        }}>
          <div style={{ ...styles.content}}>
            <Typography variant="h1" sx={styles.header}>
              {title}
            </Typography>
            <Typography variant="body1" sx={styles.subheader}>
              {subtitle}
            </Typography>
            <List style={{ display: 'flex', flexDirection: isMobile ? "column" : "row", padding: '5vh 10vw 0 10vw'}}>
              {items.map((item, index) => (
                <ListItem key={index} style={styles.listItem}>
                  <div>
                    <div>
                      <Typography variant="body1" style={{ color: '#FDD06B', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon style={{ ...styles.checkIcon, color: '#FDD06B' }} />
                        {item.title}
                      </Typography>
                      <Typography variant="body1" style={{ color: '#FEF8F1' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
    );
  }

  export default ElevateSection;